import usePortfolios, {
  useCashAndSovereigns,
  useUpdateDefaultReportingPeriod,
  useUpsertPortfolioNonEligibleFinancials,
} from 'containers/Portfolios/Portfolios.hooks';
import { ContentHeader, ContentLayout, HelpTooltip, Loader } from 'Molecules';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useCurrentCompany, useToast } from 'utils/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'utils/translation';
import { Box, HStack, VStack, useDisclosure } from '@chakra-ui/react';
import { Button, Tabs, FilterTag, Avatar, ButtonWithMenu, Infobox } from 'Atoms';
import { PortfolioPai } from 'Features/PortfolioPai';
import {
  PortfolioTaxonomy,
  PortfolioCompanyEditorModal,
  PortfolioCompanies,
  ReportYearSelector,
} from 'Features/PortfolioView';
import { Typography } from 'Tokens';
import { EditIcon } from 'Tokens/Icons/Function';
import PortfolioDefault from 'containers/Portfolios/PortfolioDefault';
import { mean } from 'lodash';
import { formatNum } from 'utils/numbers';
import { usePaiPortfolioDetails } from 'Features/PortfolioPai/PortfolioPai.hooks';
import { formatDate, formatDateToDDMMYYYY, formatQuarterYear } from 'utils/date';
import { useIsAuditor } from 'containers/CompanyPai/CompanyPai.hooks';
import { useInvalidFinancialCompanies } from './PortfolioFinancials.hooks';

import { AddCompanyIcon, CashIcon, SovereignsIcon } from 'Tokens/Icons/Data';
import { MenuSection } from 'Molecules/Menu';
import { CheckIcon } from 'Tokens/Icons/Status';
import { ReportHeaderBox } from './ReportHeaderBox';
import {
  InvestmentCategory_Enum_,
  QuarterEnum_Enum_,
  useGetPortfolioNonEligibleFinancialsQuery,
} from 'models';
import { captureException } from '@sentry/react';
import { getQuarterEnum } from '../../../backend/functions/_utils/bulk';

export enum ReportPeriods {
  'q1' = 'q1',
  'q2' = 'q2',
  'q3' = 'q3',
  'q4' = 'q4',
  'year' = 'year',
}

export const TIME_PERIOD_FILTERS: {
  key: ReportPeriods;
  value: string;
}[] = [
  {
    key: ReportPeriods.q1,
    value: 'Q1',
  },
  {
    key: ReportPeriods.q2,
    value: 'Q2',
  },
  {
    key: ReportPeriods.q3,
    value: 'Q3',
  },
  {
    key: ReportPeriods.q4,
    value: 'Q4',
  },
  {
    key: ReportPeriods.year,
    value: 'Full year',
  },
];

export const useCurrentReportPeriod = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const reportPeriod = useMemo(() => {
    const period = searchParams.get('period');
    return period ? (period as unknown as ReportPeriods) : ReportPeriods.year;
  }, [searchParams]);

  const setReportPeriod = useCallback(
    (period: ReportPeriods) => {
      setSearchParams({ period });
    },
    [setSearchParams]
  );
  return {
    reportPeriod,
    setReportPeriod,
  };
};

export const PortfolioView = () => {
  const { getPortfolio, loading } = usePortfolios();
  const { t } = useTranslation(['portfolio', 'common']);
  const { company: currentCompany } = useCurrentCompany();
  const { isAuditor, loading: auditoLoading } = useIsAuditor(currentCompany?.id);
  const { updateDefaultReportingPeriod, defaultReportingPeriod } = useUpdateDefaultReportingPeriod(
    currentCompany?.id
  );

  const {
    portfolioId,
    reportingYear: reportingYearString,
    currentTab,
  } = useParams() as unknown as {
    portfolioId: string;
    reportingYear: string;
    currentTab: string;
  };

  const reportingYear = reportingYearString?.toUpperCase();
  const [quarter, year] = reportingYear?.split('-') ?? ['', ''];

  const { data: portfolioNonEligibleFinancialsData } = useGetPortfolioNonEligibleFinancialsQuery({
    variables: {
      portfolioId,
      year: Number(year),
      quarter: getQuarterEnum(quarter),
    },
  });

  const toast = useToast();
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const navigate = useNavigate();
  const { reportPeriod, setReportPeriod } = useCurrentReportPeriod();

  const portfolio = useMemo(
    () => getPortfolio(portfolioId ?? '', reportingYear),
    [portfolioId, reportingYear, getPortfolio]
  );

  const { cash, sovereigns } = useCashAndSovereigns({
    portfolioId,
    yearAndQuarter: reportingYear,
    reportPeriod,
    defaultValue: true,
  });

  useEffect(() => {
    if (!currentTab) {
      navigate(`${defaultReportingPeriod ?? `Q1-${new Date().getFullYear()}`}/companies`);
    }
  }, [currentTab, defaultReportingPeriod]);

  useEffect(() => {
    if (!portfolio && !loading) {
      toast({
        text: t('portfolio:toast.notFound'),
        variant: 'danger',
      });
    }
  }, [portfolio, loading]);

  const years = useMemo(() => {
    const tempYears = portfolio.allYears as string[];
    if (!tempYears.includes(reportingYear)) {
      tempYears.push(reportingYear);
    }
    return tempYears.filter(Boolean).sort();
  }, [portfolio, reportingYear]);

  const portfolioCompaniesInYear = useMemo(() => portfolio?.portfolioCompanies, [portfolio]);

  const yearInvestments = useMemo(() => {
    return portfolioCompaniesInYear
      ?.map((pc) => pc.valueOfInvestments)
      .reduce(
        (acc, curr) => {
          const q1 = acc.q1 + curr.q1;
          const q2 = acc.q2 + curr.q2;
          const q3 = acc.q3 + curr.q3;
          const q4 = acc.q4 + curr.q4;
          const year = (q1 + q2 + q3 + q4) / 4;
          return {
            q1,
            q2,
            q3,
            q4,
            year,
          };
        },
        {
          q1: 0,
          q2: 0,
          q3: 0,
          q4: 0,
          year: 0,
        }
      );
  }, [portfolioCompaniesInYear]);

  const companiesOnlyInvestment = yearInvestments[reportPeriod] ?? 0;

  // PAI stuff
  const { responsiblePerson, dueDate, numberOfIndicators, progress, progressPerCompany } =
    usePaiPortfolioDetails({
      portfolioId: portfolioId ?? '',
      reportingYear,
      reportPeriod,
    });

  // Taxonomy
  const filteredReports = useMemo(
    () =>
      portfolio?.taxonomyReports?.filter(
        (report) => formatQuarterYear(report.quarter, report.year) === reportingYear
      ) || [],
    [portfolio, reportingYear]
  );

  const taxonomyReport = useMemo(
    () => (filteredReports.length > 0 ? filteredReports[0] : null),
    [filteredReports]
  );

  const companyTaxonomyProgress = useMemo(() => {
    return mean(
      portfolioCompaniesInYear?.map((pc) => {
        const totalProgress = (pc.progress.financials + pc.progress.screening) / 2;
        if (pc.progress.isLocked) return 100;
        return totalProgress;
      })
    );
  }, [portfolioCompaniesInYear]);

  useEffect(() => {
    if (!reportingYear && years?.length) {
      navigate(`${years[years.length - 1]}`);
    }
  }, [years]);

  const invalidFinancialCompanies = useInvalidFinancialCompanies(portfolio, reportPeriod);

  const cashRow = portfolioNonEligibleFinancialsData?.PortfolioNonEligibleFinancials.find(
    (pNEF) => pNEF.type === InvestmentCategory_Enum_.Cash_
  );
  const sovereignRow = portfolioNonEligibleFinancialsData?.PortfolioNonEligibleFinancials.find(
    (pNEF) => pNEF.type === InvestmentCategory_Enum_.Sovereign_
  );

  const upsertPortfolioNonEligibleFinancials = useUpsertPortfolioNonEligibleFinancials();
  const upsertNonEligibleFinancialsRow = (type: InvestmentCategory_Enum_) => {
    try {
      upsertPortfolioNonEligibleFinancials([
        {
          portfolioId: portfolioId,
          quarter: quarter as QuarterEnum_Enum_,
          year: Number(year),
          type: type,
          value: { q1: 0, q2: 0, q3: 0, q4: 0 },
          isSelected: true,
        },
      ]);
      toast({
        text: `${t('portfolio:toasts.success')}`,
      });
    } catch (error) {
      captureException(error, {
        extra: { errorMessage: 'Failed to add nonEligibleFinancial row' },
      });
      toast({
        text: `${t('portfolio:toasts.fail')}`,
        variant: 'danger',
      });
    }
  };

  const showNonEligibleFinancialsRow = (type: InvestmentCategory_Enum_) => {
    if (type === InvestmentCategory_Enum_.Cash_ && !cashRow?.isSelected) {
      upsertNonEligibleFinancialsRow(type);
    }
    if (type === InvestmentCategory_Enum_.Sovereign_ && !sovereignRow?.isSelected) {
      upsertNonEligibleFinancialsRow(type);
    }
  };

  const { isOpen: isMenuOpen, onOpen: onMenuOpen, onClose: onMenuClose } = useDisclosure();

  const menuSections: MenuSection[] = [
    {
      actions: [
        {
          id: 'addCompany',
          title: 'Add company',
          onClick: () => setIsCreating(true),
          leftElement: <AddCompanyIcon />,
        },
        {
          id: 'addCash',
          title: ' Add cash',
          leftElement: <CashIcon color={cashRow?.isSelected ? 'text.selected' : 'default'} />,
          rightElement: cashRow?.isSelected ? <CheckIcon color="text.selected" /> : undefined,
          onClick: () => showNonEligibleFinancialsRow(InvestmentCategory_Enum_.Cash_),
          variant: cashRow?.isSelected ? 'selected' : undefined,
          isDisabled: cashRow?.isSelected,
        },
        {
          id: 'addSovereigns',
          title: 'Add sovereigns',
          leftElement: (
            <SovereignsIcon color={sovereignRow?.isSelected ? 'text.selected' : 'default'} />
          ),
          rightElement: sovereignRow?.isSelected ? <CheckIcon color="text.selected" /> : undefined,
          onClick: () => showNonEligibleFinancialsRow(InvestmentCategory_Enum_.Sovereign_),
          variant: sovereignRow?.isSelected ? 'selected' : undefined,
          isDisabled: sovereignRow?.isSelected,
        },
      ],
    },
  ];

  if (loading || auditoLoading) return <Loader />;

  const financialCompaniesWarning =
    invalidFinancialCompanies.length > 0 ? (
      <Typography>
        <strong>{invalidFinancialCompanies.map((pc) => pc.company?.name).join(', ')}</strong>
        {invalidFinancialCompanies.length === 1
          ? t('portfolio:infoBox.has')
          : t('portfolio:infoBox.have')}
        {t('portfolio:infoBox.introText')}
        <strong>{t('portfolio:infoBox.valueOfInvestment')}</strong>
        {t('portfolio:infoBox.additionalText')}
        <strong>{t('portfolio:infoBox.enterpriseValue')}</strong>
        {t('portfolio:infoBox.finalText')}
      </Typography>
    ) : null;

  return (
    <ContentLayout
      backButton={true}
      onBackNavigate={() => navigate(`/${currentCompany?.id}/portfolios`)}
      header={
        <ContentHeader
          title={portfolio?.name ?? ''}
          actions={
            portfolio?.portfolioCompanies.length ? (
              <ButtonWithMenu
                leftIcon={<AddCompanyIcon color="text.action" />}
                variant="secondary"
                onClick={() => setIsCreating(true)}
                isDisabled={isAuditor}
                iconButtonVariant="secondary"
                noSpacing
                menuProps={{
                  description: t('portfolio:gettingStarted.selectInvestment'),
                  size: 'md',
                  isOpen: isMenuOpen,
                  onOpen: onMenuOpen,
                  onClose: onMenuClose,
                  sections: menuSections,
                }}
              >
                <Typography variant="bodyStrong" color="text.action">
                  {t('portfolio:gettingStarted.addInvestment')}
                </Typography>
              </ButtonWithMenu>
            ) : (
              <Button
                variant="secondary"
                onClick={() => setIsCreating(true)}
                isDisabled={isAuditor}
              >
                {t('portfolio:gettingStarted.add')}
              </Button>
            )
          }
        />
      }
    >
      {portfolio?.portfolioCompanies.length && years?.length && reportingYear ? (
        <VStack spacing="24px" alignItems="stretch">
          <VStack alignItems="stretch" mb="4px">
            <HStack
              bg="bg.muted"
              borderRadius="8px"
              px="16px"
              py="8px"
              justifyContent="space-between"
            >
              <HStack spacing="8px">
                <Typography variant="h4">PERIOD:</Typography>
                <ReportYearSelector
                  reportingYears={years}
                  selectedYear={reportingYear}
                  setSelectedYear={(newYear) => {
                    updateDefaultReportingPeriod(newYear);
                    navigate(`${newYear}/${currentTab ?? 'companies'}`);
                  }}
                  isAuditor={isAuditor}
                />
              </HStack>
              <HStack spacing="8px">
                <HStack spacing="11px">
                  <HStack spacing="6px">
                    {TIME_PERIOD_FILTERS.map((period) => (
                      <FilterTag
                        key={period.key}
                        size="sm"
                        selected={period.key == reportPeriod}
                        onClick={() => setReportPeriod(period.key)}
                      >
                        {period.value}
                      </FilterTag>
                    ))}
                  </HStack>
                  <HelpTooltip
                    label={
                      'Change portfolio composition and values of investment for each quarter.'
                    }
                  />
                </HStack>
                <Button
                  variant="ghost"
                  leftIcon={<EditIcon />}
                  onClick={() => navigate(`${reportingYear}/edit`)}
                  isDisabled={isAuditor}
                >
                  Edit
                </Button>
              </HStack>
            </HStack>

            <HStack justifyContent="space-between" spacing="12px">
              <ReportHeaderBox
                header={'Companies'.toUpperCase()}
                content={[
                  {
                    label: 'Total',
                    element: (
                      <Typography variant="h2">{portfolioCompaniesInYear?.length}</Typography>
                    ),
                  },
                  {
                    label: `${t('portfolio:invested')}, total${cash > 0 || sovereigns > 0 ? '*' : ''}`,
                    withActionButton: cash > 0 || sovereigns > 0,
                    element: (
                      <Typography variant="h2">
                        {formatNum(companiesOnlyInvestment + (cash || 0) + (sovereigns || 0)) +
                          ' ' +
                          currentCompany?.currency}
                      </Typography>
                    ),
                    extraData: {
                      cash,
                      sovereigns,
                      currency: currentCompany?.currency,
                      companiesOnlyInvestment,
                    },
                  },
                ]}
              />

              {currentCompany?.hasTaxonomyAccess && (
                <ReportHeaderBox
                  header={'EU Taxonomy'.toUpperCase()}
                  content={[
                    {
                      label: 'Completed',
                      element: (
                        <Typography variant="h2">
                          {(isNaN(companyTaxonomyProgress) ? 0 : companyTaxonomyProgress).toFixed(
                            0
                          )}
                          %
                        </Typography>
                      ),
                    },
                    {
                      label: 'Responsible',
                      element: (
                        <>
                          {taxonomyReport?.contactPerson ? (
                            <Avatar
                              boxSize="26px"
                              name={taxonomyReport?.contactPerson?.displayName ?? ''}
                            />
                          ) : (
                            <Typography variant="h2">---</Typography>
                          )}
                        </>
                      ),
                    },
                    {
                      label: 'Deadline',
                      element: (
                        <Typography variant="h2">
                          {taxonomyReport?.dueDate
                            ? formatDateToDDMMYYYY(formatDate(new Date(taxonomyReport?.dueDate)))
                            : '---'}
                        </Typography>
                      ),
                    },
                  ]}
                />
              )}

              {currentCompany?.hasPaiAccess && (
                <ReportHeaderBox
                  header={'PAI and other indicators'.toUpperCase()}
                  content={[
                    {
                      label: 'Completed',
                      element: (
                        <Typography variant="h2">
                          {numberOfIndicators || progress
                            ? `${(progress * 100).toFixed(0)}%`
                            : '---'}
                        </Typography>
                      ),
                    },
                    {
                      label: 'Indicators',
                      element: (
                        <Typography variant="h2">
                          {numberOfIndicators ? numberOfIndicators : '---'}
                        </Typography>
                      ),
                    },
                    {
                      label: 'Responsible',
                      element: responsiblePerson ? (
                        <Avatar boxSize="26px" name={responsiblePerson?.displayName ?? ''} />
                      ) : (
                        <Typography variant="h2">---</Typography>
                      ),
                    },
                    {
                      label: 'Deadline',
                      element: (
                        <Typography variant="h2">
                          {dueDate ? formatDateToDDMMYYYY(formatDate(new Date(dueDate))) : '---'}
                        </Typography>
                      ),
                    },
                  ]}
                />
              )}
            </HStack>
          </VStack>
          {financialCompaniesWarning && (
            <Infobox
              status="critical"
              closable={false}
              title={t('portfolio:infoBox.title')}
              description={financialCompaniesWarning}
            />
          )}
          <Box pb="40px">
            <Tabs
              defaultIndex={0}
              currentTab={currentTab}
              onChange={(newTab) =>
                navigate({
                  pathname: `${reportingYear}/${newTab}`,
                  search: `period=${reportPeriod}`,
                })
              }
              items={[
                {
                  title: 'Companies',
                  id: 'companies',
                  content: (
                    <PortfolioCompanies
                      reportPeriod={reportPeriod}
                      reportingPeriod={reportingYear}
                      paiProgressPerCompany={progressPerCompany}
                      isAuditor={isAuditor}
                      showCashRow={cashRow?.isSelected}
                      showSovereignRow={sovereignRow?.isSelected}
                      portfolioNonEligibleFinancials={
                        portfolioNonEligibleFinancialsData?.PortfolioNonEligibleFinancials
                      }
                    />
                  ),
                },
                {
                  title: 'EU Taxonomy',
                  id: 'eu-taxonomy',
                  content: (
                    <PortfolioTaxonomy
                      reportPeriod={reportPeriod}
                      year={reportingYear}
                      isAuditor={isAuditor}
                    />
                  ),
                  isHidden: !currentCompany?.hasTaxonomyAccess,
                },
                {
                  title: 'PAI and other indicators',
                  id: 'pai',
                  content: (
                    <PortfolioPai
                      reportingYear={reportingYear}
                      isAuditor={isAuditor}
                      paiProgressPerCompany={progressPerCompany}
                    />
                  ),
                  isHidden: !currentCompany?.hasPaiAccess,
                },
              ]}
            />
          </Box>
        </VStack>
      ) : (
        <PortfolioDefault
          setIsCreating={setIsCreating}
          years={years}
          reportingYear={reportingYear}
          currentTab={currentTab}
        />
      )}
      {isCreating && (
        <PortfolioCompanyEditorModal
          portfolioCompany={undefined}
          currentPortfolioCompanyIds={portfolio?.portfolioCompanies.map((pc) => pc.company?.id)}
          isOpen={isCreating}
          onClose={() => {
            setIsCreating(false);
          }}
          reportYear={reportingYear}
        />
      )}
    </ContentLayout>
  );
};
