import { Button, ButtonProps } from 'Atoms';
import { CashSovereignsOptions, PaiPortfolioReport } from 'models';
import { DownloadIcon } from 'Tokens/Icons/Function';
import { PaiResults, mapUnitToCompanyCurrencyPAI } from './PortfolioPai.hooks';
import ExcelJS from 'exceljs';
import { calculateMetricResults, getMetricProgress } from 'utils/scores/pai';
import { saveAs } from 'file-saver';
import { useParams } from 'react-router-dom';
import usePortfolios from 'containers/Portfolios/Portfolios.hooks';
import { useCurrentCompany } from 'utils/hooks';
import { sortTable1Indicators } from './PaiIndicatorsModal';

export const PaiExport = ({
  selectedIndicators,
  results,
  reportingYear,
  cashAndSovereigns,
  onClick,
}: {
  selectedIndicators: PaiPortfolioReport['selectedIndicators'];
  results: PaiResults | undefined;
  reportingYear: string;
  cashAndSovereigns: CashSovereignsOptions;
  onClick?: ButtonProps['onClick'];
}) => {
  const { portfolioId } = useParams();
  const {
    getPortfolio,
    // loading
  } = usePortfolios();

  const { company } = useCurrentCompany();

  const sortedIndicators = sortTable1Indicators(selectedIndicators);

  const portfolio = getPortfolio(portfolioId ?? '', reportingYear);
  const investorMetricsList = sortedIndicators
    .map((indicator) =>
      indicator.indicator.investorMetrics.map((investorMetric) => ({
        ...investorMetric.investorMetric,
        indicator: indicator.indicator,
        companyCount:
          (indicator.isForAllCompanies
            ? results?.length
            : indicator.paiReportIndicatorPortfolioCompanies.length) ?? 1,
      }))
    )
    .flat();

  const investeeMetricsList = sortedIndicators
    .map((indicator) =>
      indicator.indicator.investorMetrics.map((investorMetric) => ({
        ...investorMetric.investorMetric,
        indicator: indicator.indicator,
      }))
    )
    .flat()
    .map((investorMetric) =>
      investorMetric.investeeMetrics.map((investeeMetric) => ({
        ...investeeMetric.metric,
        investorMetric: investorMetric,
      }))
    )
    .flat();

  const onExport = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Pai Results');
    sheet.columns = [
      {
        key: 'indicator',
        header: 'Indicator',
        width: 50,
      },
      {
        key: 'metric',
        header: 'Metric',
        width: 100,
      },
      {
        key: 'impactValue',
        header: 'Impact this year',
        width: 25,
      },
      {
        key: 'impactUnit',
        header: 'Unit',
        width: 15,
      },
      {
        key: 'answers',
        header: 'Answers collected',
        width: 20,
      },
    ];

    const rows = investorMetricsList.map((investorMetric) => {
      const metricResults = calculateMetricResults(
        investorMetric,
        results ?? [],
        cashAndSovereigns
      );
      const impactValue = metricResults.result ?? 'N/A';
      const impactUnit = mapUnitToCompanyCurrencyPAI(investorMetric.unit, company?.currency) ?? '';

      const metricProgress = getMetricProgress(
        investorMetric,
        results ?? [],
        investorMetric.companyCount
      );
      const formattedProgress =
        metricProgress !== null && metricProgress !== undefined ? `${metricProgress}%` : '0%';
      return {
        indicator: investorMetric.indicator?.title ?? 'N/A',
        metric: investorMetric.title ?? 'N/A',
        impactValue: impactValue,
        impactUnit: impactUnit,
        answers: formattedProgress,
      };
    });

    const removeIllegalCharacters = (name: string): string => {
      return name.replace(/[\*\?\:\[\]\/\\]/g, '_');
    };

    sheet.addRows(rows);

    results?.forEach((res) => {
      const formatedCompanyName = removeIllegalCharacters(res.company.name);
      const companySheet = workbook.addWorksheet(`${formatedCompanyName}-raw-results`);
      companySheet.columns = [
        {
          key: 'indicator',
          header: 'Indicator',
          width: 100,
        },
        {
          key: 'metric',
          header: 'Metric',
          width: 100,
        },
        {
          key: 'question',
          header: 'Question',
          width: 100,
        },
        {
          key: 'answerValue',
          header: 'Answer',
          width: 20,
        },
        {
          key: 'answerUnit',
          header: 'Unit',
          width: 15,
        },
        {
          key: 'note',
          header: 'Note',
          width: 20,
        },
        {
          key: 'marketValue',
          header: 'Enterprise value',
          width: 20,
        },
        {
          key: 'allocation',
          header: 'Allocation',
          width: 20,
        },
      ];
      const companyRows = investeeMetricsList.map((investeeMetric) => {
        let answerValue = 'N/A';
        let answerUnit = '';

        if (investeeMetric?.reference && res.answers[investeeMetric.reference] !== undefined) {
          const rawAnswer = `${res.answers[investeeMetric.reference]}`;
          const unit = investeeMetric.unit ?? '';
          answerValue = rawAnswer;
          answerUnit = unit;
        }

        return {
          answerValue: answerValue,
          answerUnit: answerUnit,
          indicator: investeeMetric.investorMetric.indicator?.title ?? 'N/A',
          metric: investeeMetric.investorMetric.title ?? 'N/A',
          question: investeeMetric.title ?? 'N/A',
          note:
            investeeMetric?.reference && !!res.notes[investeeMetric.reference]
              ? `${res.notes[investeeMetric.reference]}`
              : '',
          marketValue: res.marketValue ?? '0',
          allocation: res.allocation ?? '0',
        };
      });
      companySheet.addRows(companyRows);
    });

    workbook.xlsx.writeBuffer().then(function (buffer) {
      // done
      console.log(buffer);

      const blob = new Blob([buffer], { type: 'applicationi/xlsx' });
      saveAs(blob, `${portfolio?.name}-pai-results.xlsx`);
    });
  };

  return (
    <Button
      onClick={(e) => {
        onExport();
        if (onClick) {
          onClick(e);
        }
      }}
      // isLoading={loading}
      leftIcon={<DownloadIcon color="inherit" />}
      variant="primary"
      alignSelf="start"
    >
      Export results
    </Button>
  );
};
