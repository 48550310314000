import {
  InputGroup,
  InputLeftElement,
  useMultiStyleConfig,
  InputProps,
  Box,
} from '@chakra-ui/react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import React from 'react';
import './DateInput.css';
import { CalendarIcon } from 'Tokens/Icons/Data';
import { Tooltip } from 'Atoms/Tooltip';
import { Typography } from 'Tokens';
import { IconButton } from 'Atoms/Buttons';
import { ChevronLeftIcon, ChevronRightIcon } from 'Tokens/Icons/Direction';

const TypedDatePicker = DatePicker as unknown as React.FC<ReactDatePickerProps>;

export type DateInputProps = Omit<ReactDatePickerProps, 'onChange' | 'value'> & {
  value: Date;
  setValue: (val: Date | null) => void;
  isBorderless?: boolean;
  attachToId?: string;
  renderQuarterContent?: (quarter: number, shortQuarter: string) => React.ReactNode;
} & Omit<InputProps, 'value'>;

export type StartToEndDateInputProps = Omit<ReactDatePickerProps, 'onChange' | 'value'> & {
  startDate: Date;
  endDate: Date;
  setStartDate: (val: Date | null) => void;
  setEndDate: (val: Date | null) => void;
  isBorderless?: boolean;
  attachToId?: string;
} & Omit<InputProps, 'value'>;

const CustomHeader = ({
  date,
  decreaseYear,
  increaseYear,
}: {
  date: Date;
  decreaseYear: () => void;
  increaseYear: () => void;
}) => {
  const currentYear = date.getFullYear();
  const startOfDecade = Math.floor(currentYear / 10) * 10 + 1;
  const endOfDecade = startOfDecade + 9;

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>
        <Tooltip label="Previous period" zIndex={300}>
          <IconButton
            variant="ghost"
            aria-label="PreviousPeriod"
            size="sm"
            alignSelf="flex-start"
            icon={<ChevronLeftIcon color="inherit" />}
            onClick={decreaseYear}
          />
        </Tooltip>
      </Box>

      <Typography variant="body" color="text.default">
        {startOfDecade} - {endOfDecade}
      </Typography>

      <Box>
        <Tooltip label="Next period">
          <IconButton
            variant="ghost"
            aria-label="NextPeriod"
            size="sm"
            alignSelf="flex-start"
            icon={<ChevronRightIcon color="inherit" />}
            onClick={increaseYear}
          />
        </Tooltip>
      </Box>
    </Box>
  );
};

export function DateInput({
  value,
  setValue,
  onChange: _onChange,
  width,
  isBorderless = false,
  attachToId = 'root',
  showYearPicker,
  ...props
}: DateInputProps) {
  const styles = useMultiStyleConfig('Input', props);
  return (
    <InputGroup
      sx={{
        input: {
          ...styles.field,
          paddingLeft: '32px',
          width: width ?? 'fit-content',
          border: isBorderless ? 'none' : '',
          _focus: isBorderless
            ? {
                border: '1px solid',
                borderColor: 'border.selected.accent',
                borderRadius: '4px',
              }
            : {},
        },
      }}
      size="md"
      width={width ?? '100%'}
      zIndex={1}
    >
      <InputLeftElement
        pointerEvents="none"
        color="text.hint"
        fontSize="14px"
        height={props.size === 'md' ? '24px' : '34px'}
        width="22px"
        pl="10px"
      >
        <CalendarIcon />
      </InputLeftElement>
      <TypedDatePicker
        selected={new Date(value)}
        showYearPicker={showYearPicker}
        yearItemNumber={10}
        onChange={(val: Date | null) => setValue(Number.isNaN(val) ? null : val)}
        onMonthChange={(val: Date | null) => setValue(Number.isNaN(val) ? null : val)}
        onYearChange={(val: Date | null) => setValue(Number.isNaN(val) ? null : val)}
        className="chakra-input"
        dateFormat="yyyy-MM-dd"
        placeholderText="2022"
        autoComplete="on"
        popperContainer={(popper) => <Box zIndex={9999} children={popper.children} />}
        portalId={attachToId}
        renderCustomHeader={
          showYearPicker
            ? ({ date, decreaseYear, increaseYear }) => {
                return (
                  <CustomHeader
                    date={date}
                    decreaseYear={decreaseYear}
                    increaseYear={increaseYear}
                  />
                );
              }
            : undefined
        }
        {...props}
      />
    </InputGroup>
  );
}
