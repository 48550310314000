import { Paragraph, Table, TableCell, TableRow, VerticalAlign, WidthType } from 'docx';
import { EsrsReportMetric } from '../../Report.types';
import { TextStyle, tableBorders } from '../reportThemes';
import { findMetricRow } from '../report-helper-functions';

const E1_5Metrics = [
  {
    reference: 'totalFuelConsumptionCoal',
    title: '(1) Fuel consumption from coal and coal products (MWh)',
  },
  {
    reference: 'totalFuelConsumptionCrude',
    title: '(2) Fuel consumption from crude and petroleum products (MWh)',
  },
  {
    reference: 'totalFuelConsumptionGas',
    title: '(3) Fuel consumption from natural gas (MWh)',
  },
  {
    reference: 'totalFuelConsumptionOther',
    title: '(4) Fuel consumption from other fossil sources (MWh)',
  },
  {
    reference: 'totalEnergyConsumptionPurchasedFossil',
    title:
      '(5) Consumption of purchased or acquired electricity, heat, steam, and cooling from fossil sources (MWh)',
  },
  {
    reference: 'energyConsumptionFossil',
    title: '(6) Total fossil energy consumption (MWh) (calculated as the sums of lines 1 to 5)',
    style: TextStyle.bodyBold,
  },
  {
    reference: 'percentageFossilConsumption',
    title: 'Share of fossil sources in total energy consumption (%)',
    style: TextStyle.bodyBold,
  },
  {
    reference: 'totalConsumptionNuclear',
    title: '(7) Consumption from nuclear sources (MWh)',
    style: TextStyle.bodyBold,
  },
  {
    reference: 'percentageNuclearConsumption',
    title: 'Share of consumption from nuclear sources in total energy consumption (%)',
    style: TextStyle.bodyBold,
  },
  {
    reference: 'fuelConsumptionRenewable',
    title:
      '(8) Fuel consumption for renewable resources, including biomass (also comprising industrial and municipal waste of biologic origin, biogas, renewable hydrogen, etc.) (MWh)',
  },
  {
    reference: 'consumptionPurchasedRenewable',
    title:
      '(9) Consumption of purchased or acquired electricity, heat, steam, and cooling from renewable recourses (MWh)',
  },
  {
    reference: 'consumptionSelfgeneratedRenewable',
    title: '(10) The consumption of self-generated non-fuel renewable energy (MWh)',
  },
  {
    reference: 'energyConsumptionRenewables',
    title:
      '(11) Total renewable energy consumption (MWh) (calculated as the sums of lines 8 to 10)',
    style: TextStyle.bodyBold,
  },
  {
    reference: 'percentageRenewableConsumption',
    title: 'Share of renewable sources in total energy consumption (%)',
    style: TextStyle.bodyBold,
  },
  {
    reference: 'totalEnergyConsumption',
    title: 'Total energy consumption (MWh) (calculated as the sums of lines 6, 7 and 11)',
    style: TextStyle.bodyBold,
  },
];

const createRows = (dataRows: { title: string; answer: string; style?: any }[]) => {
  return dataRows.map((rowData) => {
    return new TableRow({
      children: [
        new TableCell({
          verticalAlign: VerticalAlign.TOP,
          children: [new Paragraph({ text: rowData.title, style: rowData.style })],
        }),
        new TableCell({
          verticalAlign: VerticalAlign.TOP,
          children: [
            new Paragraph({
              text: rowData.answer,
              style: TextStyle.body,
            }),
          ],
        }),
      ],
    });
  });
};

export const createE15Table = (metrics: EsrsReportMetric[], year: number) => {
  const dataRows = E1_5Metrics.flatMap((metric) => {
    const title = metric.title;
    const rows = metrics.map((m) => m.tableData);
    const metricRow = findMetricRow(metric, rows);
    const answer = metricRow?.result?.Year;
    return {
      title,
      answer: `${answer ? parseFloat(answer.toFixed(2)) : ''}`,
      style: metric.style ?? TextStyle.body,
    };
  });

  const tableContentRows = createRows(dataRows);

  const headerRow = new TableRow({
    tableHeader: true,
    children: [
      new TableCell({
        verticalAlign: VerticalAlign.CENTER,
        width: { size: '80%', type: WidthType.PERCENTAGE },
        children: [
          new Paragraph({ text: 'Energy consumption and mix', style: TextStyle.tableTitle }),
        ],
      }),
      new TableCell({
        verticalAlign: VerticalAlign.CENTER,
        width: { size: '20%', type: WidthType.PERCENTAGE },
        children: [new Paragraph({ text: `${year}`, style: TextStyle.tableTitle })],
      }),
    ],
  });

  return new Table({
    columnWidths: [8000, 2000],
    margins: { top: 60, bottom: 60, right: 60, left: 60 },
    width: { size: '100%', type: WidthType.PERCENTAGE },
    borders: tableBorders,
    rows: [headerRow, ...tableContentRows],
  });
};
